<template>
    <div class="agreement">
    <div class="div">
        <h2>【服务消费者】用户协议</h2>
        <h4>
            特别提示：
        </h4>
        <p>
            你在使用四川线上客信息技术开发有限公司（以下简称“线上客”）及关联公司提供的各项服务之前，请你务必审慎阅读、充分理解本协议各条款内容，特别是以粗体标注的部分，包括但不限于免除或者限制责任的条款。如你不同意本服务协议及/或随时对其的修改，你应立即停止注册；你一旦使用线上客提供的服务，即视为你已了解并完全同意本服务协议各项内容，包括线上客对服务协议所做的修改，并成为我们的用户。
        </p>
        <h4>
            一、帐号注册
        </h4>
        <p>
         1、当你注册成功帐号并且与某个线上客提供技术支持的店铺发生行为关联时（包括不限于获得店铺优惠券、登录商家店铺后台、产生购买行为），你授权该商家获得你的手机帐号以方便下单购买。
        </p>
        2、当你选择通过第三方授权登录程序进入线上客，你需授权线上客收集你的帐号昵称及头像等标识化的个人信息，授权完成后，你即成为线上客的“用户”。
        <p>

        </p>
        当你按照注册页面提示填写信息并注册成功后，你即成为线上客的“用户”；线上客将给予你一个用户帐号，密码由你自行设置。该用户帐号和密码由你自行负责保管。
        你应当对你在线上客进行的活动和事件负法律责任
        <p>
        </p>
        3、本协议服务条款和公告可由线上客定时更新，并按照法定流程进行公示。你在使用相关服务时,应关注并遵守其所适用的相关条款。

        <p>
            4、使用同一身份认证信息、同一手机号或经线上客排查认定多个线上客账户的实际控制人为同一人的，均视为同一用户。

        </p>

        <p>
            5、你确认，在你开始使用本服务前，你应当具备中华人民共和国法律规定的与你行为相适应的民事行为能力。若你不具备前述与你行为相适应的民事行为能力，则你需要在你监护人的陪同下完成注册，并且你与你的监护人应依照法律规定承担因此而导致的一切后果。

        </p>


        <h4>
            二、注册信息和隐私保护

        </h4>

        <p>
            1、你帐号的所有权归线上客，使用权归你。你按注册页面引导填写信息，阅读并同意本协议且完成全部注册程序后，即可获得注册帐号并成为用户。你应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实或更新不及时而引发的相关问题，由你自行承担相应的责任。

        </p>
        <p>
            2、你应当通过真实身份信息认证注册帐号，且你提交的帐号名称、头像、 简介等注册信息中不得出现违法和不良信息，经公司审核，如存在上述情况，线上客将不予注册；同时，在注册后，如发现你以虚假信息骗取帐号名称注册，或其帐号头像、简介等注册信息存在违法和不良信息的，线上客有权不经通知单方采取限期改正、暂停使用、注销登记、收回等措施。

        </p>
        <p>
            3、你帐号包括帐户名称和密码，账户名称作为你有效的身份凭证之一。你可使用手机号和密码登录。

        </p>
        <p>
            4、你不应将其帐号、密码转让、出售或出借予他人使用，若你授权他人使用帐户，应对被授权人在该帐户下发生所有行为负全部责任。由于你其他账户使用信息，仅当依法律法规、司法裁定或经线上客同意，并符合线上客规定的用户帐号转让流程的情况下，方可进行帐号的转让。

        </p>
        <p>
            5、因你个人原因导致的帐号信息遗失，如需找回帐号信息，请按照帐号找回要求提供相应的信息，并确保提供的信息合法真实有效，若提供的信息不符合要求，无法通过安全验证，线上客有权拒绝提供帐号找回服务；若帐号的唯一凭证不再有效，线上客有权拒绝支持帐号找回。例如手机号二次出售，线上客可拒绝支持帮助找回原手机号绑定的帐号。

        </p>
        <div>
            6、在需要终止使用帐号服务时，符合以下条件的，你可以申请注销你的帐号：
            <p>
                （1）你仅能申请注销你本人的帐号，并依照线上客的流程进行注销；

            </p>
            <p>
                （2）你仍应对你在注销帐号前且使用的行为承担相应责任，同时线上客仍可保存你注销前的相关信息；

            </p>
            <p>
                （3）注销成功后，帐号信息、个人身份信息、交易记录、会员权益等将无法恢复或提供。

            </p>
        </div>
        <div>
            7、当你使用线上客产品进行支付、登录等操作的时候，服务器会自动接收、验证、并记录一些必要的信息，如手机号码、IP地址、服务访问异常信息、以及部分设备信息等，以保障你在使用服务时账户登录和支付过程的安全，进而保护你的上网安全。你使用线上客的服务，即表示你同意线上客可以按照《隐私政策》处理你的个人信息。线上客可能会与合作伙伴共同向你提供你所要求的服务或者共同向你展示你可能感兴趣的内容。在信息为该项产品/服务所必须的情况下，你同意线上客可与其分享必要的信息。并且，线上客会要求其确保数据安全并且禁止用于任何其他用途。除此之外，线上客不会向任何无关第三方提供或分享信息。
        </div>
        <div>
            8、你知悉并授权，线上客仅在必需的情况下使用或与关联公司同步你的信息，以为你提供更好的服务。
        </div>
        <div>
            9、为更好地向你提供服务，你同意线上客通过短信、app通知等形式向你发送相关商业性服务信息。
        </div>
        <h4>
            三、使用规则
        </h4>
        <div>
            1、你确认，当你注册成功并且与某个店铺发生行为关联时，如你获得商家的优惠券、登录商家店铺后台、产生购买行为等，你确认并授权线上客提供技术支持的店铺获得你的手机号，以方便你的购买。
        </div>
        <div>
            2、你在使用线上客的服务时，必须遵守中华人民共和国相关法律法规的规定，你应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为:

        </div>
        <div>
            （1）你设置的会员名或提供的信息不得含有下列内容之一的信息：
            <ul>
                <li>
                    　　① 反对宪法所确定的基本原则的；

                </li>
                <li>
                    　　② 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；

                </li>
                <li>
                    　　③ 损害国家荣誉和利益的；

                </li>
                <li>
                    　　④ 煽动民族仇恨、民族歧视、破坏民族团结的；

                </li>
                <li>
                    　　⑤ 破坏国家宗教政策，宣扬邪教和封建迷信的；

                </li>
                <li>
                    　 　　⑥ 散布谣言，扰乱社会秩序，破坏社会稳定的；


                </li>
                <li>
                    　　⑦ 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；

                </li>
                <li>
                    　　⑧ 侮辱或者诽谤他人，侵害他人合法权利的；

                </li>
                <li>
                    　 　　⑨ 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；


                </li>
                <li>
                    　　 　　⑩ 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；


                </li>


            </ul>
        </div>
        <div>
            （2）不得为任何非法目的而使用线上客服务系统；

        </div>
        <div>
            （3）不利用线上客的服务从事以下活动：
            <ul>
                <li> 　
                    　① 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；
                </li>
                <li>
                    　　② 未经允许，对计算机信息网络功能进行删除、修改或者增加的；
                </li>
                <li> 　
                    　③ 未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
                </li>
                <li> 　
                    　④ 故意制作、传播计算机病毒等破坏性程序的；
                </li>
                <li> 　
                    　⑤ 其他危害计算机信息网络安全的行为。
                </li>

            </ul>
        </div>
        <div>
            3、你违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，你同意赔偿线上客与合作公司、关联公司，并使之免受损害。对此，线上客有权视你的行为性质，采取包括但不限于删除你发布信息内容、暂停使用许可、终止服务、限制使用、回收帐号、追究法律责任等措施。对恶意注册帐号或利用帐号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，线上客有权回收其帐号。同时，线上客会视司法部门的要求，协助调查。

        </div>
        <div>
            4、你需要对自己在服务使用过程中的行为负法律责任，即使你已经终止使用该服务。你承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在线上客首先承担了因你的行为导致的行政处罚或侵权损害赔偿责任后，你应给予线上客等额的赔偿。

        </div>
        <div>
            5、线上客有权根据认定程序及标准判断你账户的使用可能危及你的账户安全及/或线上客平台信息安全的，线上客可拒绝提供相应服务或终止本协议。

        </div>
        　　<h4>
        四、服务内容

    </h4>
        <div>
            1、你在交易过程中与其他用户发生争议的，你或其他用户中任何一方可以自行协商解决，也可以通过线上客介入处理，若你觉得以上的方式均无法达到你要的结果，你可以寻求司法机关的协助。

        </div>
        <div>
            2、你选择线上客介入的方式处理相关问题，那么你需要遵守和执行线上客的处理结果。若你对线上客的处理结果不满意，你可以寻求其他途径解决纠纷例如起诉、仲裁等方式，但在此之前你需先履行该结果。

        </div>
        <div>
            3、线上客目前为你提供免费提供服务，但线上客保留因业务调整或者其他法律法规要求向你收取费用的权利。

        </div>
        <div>
            4、线上客网络服务的具体内容由线上客根据实际情况提供。

        </div>
        <div>
            5、鉴于网络服务的特殊性，你同意线上客有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。线上客不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。

        </div>
        <div>
            6、你需遵守线上客7天无理由退换货的相关规则。

        </div>
        <div>
            6、你需遵守线上客7天无理由退换货的相关规则。

        </div>
        <div>
            7、收货后结算服务，即第三方代收代付功能：在资金支付完成前（故该服务无法提供给即时到账、储值卡充值等未经线上客支付代管资金的业务），由交易相对方提供不可撤销地授权线上客支付，由线上客支付（或线上客支付的协议银行）进行资金代为收取、支付、存放、划转。在使用该功能时,买家按照流程点击确认收货或根据买卖双方的约定视为确认收货后，线上客支付将代为收取的买家支付的款项代为支付给卖家。若买卖双方与相关交易平台就全部或部分交易款项的支付时间存在其他特殊约定的，则按照特殊约定执行，如：预付卡支付方式（储值卡等），以及其他排除线上客支付的情形，如：虚拟类商品交易（话费充值、培训课程等）。

        </div>
        <div>
            8、终止服务
        </div>
        <div>
            你同意线上客得基于其自行之考虑，因任何理由，或线上客认为你已经违反本服务协议的文字及精神，终止你的密码、帐号或本服务之使用（或服务之任何部分），并将你在本服务内任何内容加以移除并删除。你同意依本服务协议任何规定提供之本服务，无需进行事先通知即可中断或终止，你承认并同意，线上客可立即关闭或删除你的帐号及你帐号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，你同意若本服务之使用被中断或终止或你的帐号及相关信息和文件被关闭或删除，线上客对你或任何第三人均不承担任何责任。

        </div>


        <h4>
            五、个人数据保护与授权

        </h4>
        <div>
            1、为了保护你的财产及信息安全，预防诈骗或者其他网络犯罪行为，线上客有权收集你的个人信息，包括但不限于个人会员信息、交易信息来判断你个人的交易风险，对你身份进行验证等。

        </div>
        <div>

        </div>
        <div>
            2、根据相关法律法规规定，以下情形中收集你的个人信息无需征得你的授权同意：
            <ul>
                <li>
                    ①、涉及公共安全，与重大利益相关的；
                </li>
                <li>
                    ②、与政府部门包括司法机关等相关的；
                </li>
                <li>
                    ②、与政府部门包括司法机关等相关的；
                </li>
                <li>
                    ③、出于保护你的个人财产或者利益的目的，但无法事先征求你同意的行为；
                </li>
                <li>
                    ④、你以自行对外公开的，包括但不限于通过报纸、新闻等方式公开；
                </li>
                <li>
                    ⑤、根据你的要求签订合同所必需的；
                </li>
                <li>
                    ⑥、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
                </li>
                <li>
                    ⑦、国家法律法规政策认可或支持的相关行为；
                </li>
            </ul>
        </div>
        <div>
            3、我们已采取符合业界标准、合理可行的安全防护措施保护你提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。

        </div>
        <h4>
            六、免责声明

        </h4>
        <div>
            因以下情况造成网络服务在合理时间内的中断，线上客无需为此承担任何责任；
            <ul>
                <li>
                    （1）线上客需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，线上客保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。
                </li>
                <li>
                    （2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；
                </li>
                <li>
                    （3）你的电脑软硬件和通信线路、供电线路出现故障的；
                </li>
                <li>
                    （4）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。
                </li>
            </ul>
        </div>
        <div>
            尽管有前款约定，线上客将采取合理行动积极促使服务恢复正常。

        </div>
        <div>
            七、知识产权和其他合法权益
            <ul>
                <li>
                    1、线上客产品及相关网站上由线上客上传、制作、拥有的所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由线上客或其关联公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。但如因你上传的由其自行制作、拥有的内容涉及侵犯线上客或其他任何第三方的合法权益的，你应自行对其侵权行为产生的纠纷进行处理，并对其侵权行为承担法律责任，且就由此给线上客造成的损失（包括但不限于线上客声誉的影响、线上客由此承担的连带责任（如有）等）进行赔偿。
                </li>
                <li>
                    2、非经线上客或其关联公司书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表在本网站上程序或内容（仅限于由线上客上传、制作、拥有的所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计等）；如你需使用著作权非线上客所有的内容的，你应获得具体内容的著作权所有者的合法授权才能使用，如因你私自使用非自己所有的、且未经他人合法授权的著作、图片、档案、资讯、资料等内容的，由你自行承担责任，包括但不限于你自行对其侵权行为产生的纠纷进行处理，并对其侵权行为承担法律责任，且就由此给乙方造成的损失（包括但不限于乙方声誉的影响、乙方由此承担的连带责任（如有）等）进行赔偿。
                </li>
                <li>
                    3、尊重知识产权是你应尽的义务，如有违反，你应承担损害赔偿责任。
                </li>

            </ul>
        </div>
        <h4>
            八、其他

        </h4>
        <div>
            1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。

        </div>
        <div>
            2、 如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余条款则仍具有法律效力。
        </div>
        <div>
            3、本协议任何一方于另一方过失或违约时放弃本协议规定的权利的，不得视为其对一方的其他或以后同类之过失或违约行为弃权。
        </div>
        <div>
            4、线上客未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。
        </div>
        <div>
            5、成都盛夏光年数字科技有限公司和四川线上客信息技术开发有限公司是同一实际控制人控制下的两家企业，故本合同项下部分权利义务由四川线上客信息技术开发有限公司履行。
        </div>
        <div>
            6、本协议最终解释权及修订权在法律范围内归线上客所有。
        </div>
        <div>
            7.【管辖法院】你因使用线上客服务产生的争议，由线上客与你协商解决。协商不成时，任何一方均可向四川省成都市青羊区人民法院提起诉讼。
        </div>

    </div>
    </div>
</template>

<script>
    export default {
        name: "agreement"
    }
</script>

<style scoped>
    * {
        margin: 0 auto;
        padding: 0;
        width: 100%;
    }
h2{
    margin:  0 10px 30px;
    text-align: center;
    position: relative;
    top:-10px
}
    .agreement {
        width: 100%;
    }
    .div {
        width: 950px;
        padding: 30px 50px;
        /*border: 1px solid #d2cfcf;*/
        border-radius: 5px;
        margin: 10px;
        position: relative;
        left: 25%;
        text-align: justify;
    }
    ul li{
        list-style: none;

    }
</style>